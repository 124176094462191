/************ Custom Font ************/
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Book.ttf');
	font-weight: 400;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}


@media (min-width:1700px) {
	main.container {
		max-width: 100%;
		padding: 0 150px;
    
	}
}

p.success {
	color: green;
}

p.danger {
	color: red;
}
/************ Navbar Css ************/
nav.navbar {
  padding: 8px 0;
  width: 100%;
  top: 0;
  z-index: 1;
  transition: 0.32s ease-in-out;
  background: rgba(0, 0, 0, 0); /* 完全透明 */
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #121212;
}
nav.navbar a.navbar-brand {
    width: 9%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
}
span.navbar-text {
    display: flex;
    align-items: center;
}
.social-icon {
    display: inline-block;
    margin-left: 14px;
}
.social-icon a {
    width: 42px;
    height: 42px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
    position: relative;
}
.social-icon a::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    transform: scale(0);
	transition: 0.3s ease-in-out;
  z-index: 0;
}
.social-icon a:hover::before {
    transform: scale(1);
}
.social-icon a img {
  
	width: 100%;
	z-index: 1;
	transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 12px 30px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #121212;
}
.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
    box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #fff;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}

/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 100px 0 100px 0;
  background-image: url('./assets/img/banner-bg.png');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1; /* Adjusted line-height to provide spacing */ 
  display: block;
  white-space: nowrap; /* Prevent wrapping */
}

.banner p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}

.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}

.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}

.banner button:hover svg {
  margin-left: 25px;
}

.banner img {
  animation: updown 3s linear infinite;
}

@keyframes updown {
  0% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

.float {
  animation: float 4s ease-in-out infinite; /* Adjust the 4s to control the speed */
  position: relative;
}

.txt-rotate {
  display: inline-block;
  height: 75px; /* Adjust to the height of the text */
  overflow: hidden; /* Ensures text does not overflow */
  margin-top: 20px; /* Add margin to separate from the main title */
}

.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
  display: inline-block;
  height: 75px; /* Ensures consistent height */
  line-height: 75px; /* Centers the text vertically */
}

/************ Skills Css ************/
.skill {
  padding: 0 0 50px 0;
  position: relative;
  background-image: url('./assets/img/color-sharp.png'); /* 添加背景图片 */
  background-size: cover; /* 确保图片覆盖整个容器 */
  background-position: center; /* 居中显示背景图片 */
  background-repeat: no-repeat; /* 防止背景图片重复 */
}

.skill-bx {
  background: rgba(21, 21, 21, 0.8); /* 添加透明背景以区分内容和背景图片 */
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}

.skill h2 {
  font-size: 45px;
  font-weight: 700;
}

.skill p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0 75px 0;

  
}

.skill-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
  z-index: 1; /* Ensures the slider has a lower z-index */
}

.skill-slider .item img {
  width: 50%;
  margin: 0 auto 15px auto;
}

.background-image-left {
  display: none; /* 隐藏多余的背景图片 */
}

.custom-left-arrow,
.custom-right-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.137);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.custom-left-arrow {
  left: -1px; /* Adjust the left value to position the arrow correctly */
}

.custom-right-arrow {
  right: -1px; /* Adjust the right value to position the arrow correctly */
}

.custom-left-arrow::before,
.custom-right-arrow::before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  border-top: 2px solid white;
  border-right: 2px solid white;
}

.custom-left-arrow::before {
  transform: rotate(-135deg);
}

.custom-right-arrow::before {
  transform: rotate(45deg);
}
/************ Projects Css ************/
.project {
  padding: 80px 0 120px 0;
  position: relative;
  background-image: url('./assets/img/color-sharp.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.project h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
}

.project p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 70%;
}

.project-info h4, .project-info p, .project-info a {
  text-align: left; /* 将标题、段落和链接文本左对齐 */
  width: 100%; /* 确保文本元素宽度占满容器 */
}

.project-card-image img {
  display: block; /* 确保图片默认居中对齐 */
  margin: 0 auto; /* 自动边距实现水平居中 */
}

.project-card-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  width: 100%;
}

.project-card {
  background: #212121;
  border-radius: 18px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  width: calc(33.333% - 20px);
  max-width: 340px; /* Set maximum width */
  min-height: 400px; /* Set minimum height */
  height: 400px; /* Set fixed height */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.project-card-image {
  position: relative;
}

.project-card-image img {
  width: 100%;
  height: 220px;
  object-fit: cover;
  border-radius: 18px 18px 0 0;
  transition: transform 0.3s ease;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.overlay-text {
  font-size: 20px;
  text-align: center;
  padding: 0 10px;
}

.project-card:hover .overlay {
  opacity: 1;
}

.project-card:hover {
  transform: scale(1.02);
  box-shadow: 0 10px 18px rgba(0, 0, 0, 0.3);
}

.project-card:hover img {
  transform: scale(1.05);
}

.project-info {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.project-info h4 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
  color: #fff;
  white-space: nowrap;
  text-align: center; /* Ensure title is centered */
}

.project-info p {
  font-size: 16px;
  color: #fff;
  text-align: left;
}

.project-info p.description {
  text-align: left;
  display: block;
  white-space: normal;
  overflow: visible;
  margin-bottom: 3px;
}

.project-info p.description:hover {
  color: #ff69b4;
}

.project-info .date {
  font-size: 14px;
  color: #b8b8b8;
  margin-bottom: 35px;
  text-align: left;
}

.project-info a {
  margin-top: 15px; 
  color: #b8b8b8;
  text-decoration: none;
  display: block;
  text-align: left;
}

.project-info a:hover {
  color: #ff69b4;
}

.project-info a::after {
  content: '→';
  margin-left: 5px;
}

.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}


/************ Contact Css ************/

.contact {
  background: linear-gradient(90.21deg, #201b12  -5.91%, #1f1746 111.58%);
  padding: 60px 0 70px 0;
}
.contact img {
  width: 92%;
}
.contact h2 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
}
.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
}
.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}
.date-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}
.date-wrapper label {
  flex: 1;
  white-space: nowrap;
  margin-left: 17px;
}
.date-wrapper input {
  flex: 2;
  width: auto;
  min-width: 150px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}

@media (min-width: 1024px) {
  .contact form .row {
    display: flex;
    flex-wrap: wrap;
  }
  .contact form .row .col-half {
    flex: 1 1 50%;
    padding: 0 10px;
  }
  .contact form .row .col-full {
    flex: 1 1 100%;
  } 
  .project-card {
    width: calc(33.333% - 25px); /* 适当增加间距 */
    height: 440px; /* 可增加高度 */
    max-width: 380px;
    min-height: 440px;
  }
}

@media (max-width: 1024px) {
  .date-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  .date-wrapper label {
    margin-left: 15px;
    width: 100%;
  }
  .date-wrapper input {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .date-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  .date-wrapper label {
    width: 100%;
    margin: 5px 0;
    margin-left: 20px;
  }
  .date-wrapper input {
    width: 100%;
    margin: 5px 0;
  }
  .contact form .row {
    flex-direction: column;
  }
  .contact form .row .col {
    width: 100%;
    padding: 0 0 8px 0;
  }
}

/************ Footer Css ************/
.footer {
 
  background-image: url('./assets/img/footer-bg.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
 
  padding: 30px 0; /* 设置上下边距 */
  text-align: center; /* 设置所有内容居中对齐 */
}
/* .newsletter-bx {
  background: #FFFFFF;
  border-radius: 55px;
  color: #121212;
  padding: 85px 125px;
  margin-bottom: 80px;
  margin-top: -122px;
}
.newsletter-bx h3 {
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.2em;
} */
.new-email-bx {
  background: #fff;
  padding: 5px;
  border-radius: 20px;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
}
.new-email-bx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
}
.new-email-bx::after {
  content: "";
  background: #fff;
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.new-email-bx input {
  width: 100%;
  color: #121212;
  font-weight: 500;
  background: transparent;
  border: 0;
  padding: 0 15px;
}
.new-email-bx button {
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  padding: 20px 65px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 18px;
}

.footer .social-icon {
  margin-bottom: 20px; /* 设置图标下方的空隙 */
}

.footer .social-icon a {
  margin: 0 10px; /* 设置图标之间的间距 */
}

.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin: 0; /* 去除默认的外边距 */
}

@media (max-width: 768px) {
  .navbar-brand img {
    height: 35px; /* Slightly smaller logo on smaller screens */
    width: auto; /* Maintain aspect ratio */
  }

  .navbar-nav {
    align-items: center;
    text-align: center;
  }

  .navbar-nav .nav-link {
    padding: 10px 0;
    margin: 0 5px;
  }

  .navbar-text {
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }

  .navbar-text .social-icon {
    margin-bottom: 10px;
  }

  .navbar-text .vvd {
    padding: 9px 21px; 
    font-size: 14px; 
    margin-left: 3px;
  }
  .banner {
    padding: 60px 20px 60px 20px;
  }
  
  .banner h1 {
    font-size: 30px;
    line-height: 1.2; /* Adjusted line height for better spacing */
    margin-bottom: 10px; /* Reduced margin for less spacing */
  }
  
  .banner p {
    font-size: 14px;
  }
  
  .banner button {
    font-size: 18px;
    margin-top: 40px;
  }
  
  .banner button svg {
    font-size: 20px;
  }
  
  .txt-rotate {
    display: inline-block;
    height: 40px; /* Adjusted height for mobile view */
    overflow: hidden; /* Ensures text does not overflow */
    margin-top: 5px; /* Reduced margin for less spacing */
    white-space: nowrap; /* Prevent wrapping */
  }
  
  .txt-rotate > .wrap {
    border-right: 0.1em solid #666; /* Adjusted cursor size */
    display: inline-block;
    height: 40px; /* Ensures consistent height */
    line-height: 40px; /* Centers the text vertically */
    font-size: 30px; /* Ensure the text size is appropriate */
  }
  .skill {
    padding: 0 20px 30px 20px;
  }
  .skill h2 {
    font-size: 25px;
  }
  .skill p {
    font-size: 14px;
    margin: 14px 0 50px 0;
  }
  .skill-bx {
    padding: 40px 30px;
    margin-top: -40px;
  }
  
  .project {
    padding: 30px 20px 80px 20px;
  }
  .project h2 {
    font-size: 25px;
  }
  .project p {
    font-size: 14px;
    width: 100%;
  }
  .project .nav.nav-pills .nav-link {
    font-size: 9.5px;
    padding: 8px 0;
    width: 100%;
  }

  .project-card {
    width: 100%; /* 卡片宽度占满整个屏幕宽度 */
    height: auto; /* 高度自动调整 */
    min-height: 320px; /* 设置最小高度以保持视觉整洁 */
  }

  .project-card-container {
    flex-direction: column;
  }

  
  .contact {
    padding: 40px 20px 50px 20px;
  }
  .contact h2 {
    font-size: 35px;
    margin-bottom: 20px;
  }
  .contact form input, .contact form textarea {
    padding: 12px 20px;
    font-size: 16px;
  }
  .contact form button {
    padding: 10px 30px;
    font-size: 16px;
    margin-top: 20px;
  }

  .date-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  .date-wrapper label {
    width: 100%;
    margin-left: 10px;
    margin-bottom: 5px;
  }
  .date-wrapper input {
    width: 100%;
  }

  .footer {
    padding: 20px 20px;
  }
  .footer p {
    font-size: 12px;
  }
  .footer .social-icon {
    margin-bottom: 15px;
  }
  .footer .social-icon a {
    margin: 0 8px;
  }

  
}

@media (min-width: 768px) and (max-width: 1024px) {
  
  .banner h1 {
    font-size: 30px;
    line-height: 1.2;
  }

  .banner p {
    font-size: 16px;
  }

  .banner button {
    font-size: 16px;
    padding: 8px 20px;
    margin-top: 30px;
  }

  .txt-rotate {
    height: 45px;
  }

  .txt-rotate > .wrap {
    font-size: 30px;
    line-height: 30px;
  }

  .project-card {
    width: calc(50% - 20px); /* 增加宽度以适应屏幕 */
    height: 420px; /* 调整高度 */
    max-width: none; /* 移除最大宽度限制 */
  }
}